<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="table"
          title="안전작업표준 목록"
          tableId="riskHazrd01"
          :columns="grid.columns"
          :data="grid.data"
          @linkClick="linkClick"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
                <c-btn label="신규" icon="add" @btnClicked="openPop" />
              <c-btn label="검색" icon="search" @btnClicked="getList" />
            </q-btn-group>
          </template>
        </c-table>
        <c-dialog :param="popupOptions"></c-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'sop-manager',
  data() {
    return {
      searchParam: {
        plantCd: null,
        keyword: '',
        useFlag: 'Y',
      },
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장명',
            align: 'center',
            sortable: true,
          },
          {
            name: 'revisionNum',
            field: 'revisionNum',
            label: 'Rev.',
            align: 'center',
            style: 'width:50px',
            sortable: true,
          },
          {
            name: 'processName',
            field: 'processName',
            label: '공정명',
            align: 'center',
            sortable: true,
          },
          {
            name: 'sopNo',
            field: 'sopNo',
            label: '안전작업표준 관리번호',
            align: 'center',
            sortable: true,
            style: 'width: 160px',
          },
          {
            name: 'sopName',
            field: 'sopName',
            label: '안전작업표준 작업명',
            align: 'left',
            sortable: true,
            type: 'link'
          },
          {
            name: 'hazardGradeName',
            field: 'hazardGradeName',
            label: '위험등급',
            align: 'center',
            sortable: true,
          },
          {
            name: 'workTypeName',
            field: 'workTypeName',
            label: '작업유형',
            align: 'center',
            sortable: true,
          },
          {
            name: 'workKindName',
            field: 'workKindName',
            label: '작업형태',
            align: 'center',
            sortable: true,
          },
          {
            name: 'workClassName',
            field: 'workClassName',
            label: '작업구분',
            align: 'center',
            sortable: true,
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.mdm.sop.job.list.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col, index) {
      this.openPop(row);
    },
    openPop(row) {
      this.popupOptions.title = '안전작업표준 상세'; // 작업허가서 상세
      this.popupOptions.param = {
        mdmSopId: row ? row.mdmSopId : '',
      };
      this.popupOptions.target = () => import(`${'./sopDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
